import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import classnames from 'classnames';

import AvatarBusiness from 'components/Avatar/Business';
import { selectMyBusiness } from 'portals/business/store/selectors';
import { getMyBusinessToken } from 'portals/business/store/actions';
import { cbChangeBusiness } from 'helpers/common';

import Select from 'components/Select';
import MenuItem from 'components/MenuItem';
import Button from 'components/Button';

import { ROUTE_BUSINESS } from '../../routes';

import useStyles from './styled';

const BusinessItem = ({ business, isInputValue }) => {
  const classes = useStyles();
  return (
    <div className={classes.portalBusiness}>
      <AvatarBusiness
        business={business}
      />
      <div className="business-info">
        <p className="name">{business?.name}</p>
        {!isInputValue && (
          <p className="type">
            <span>{business?.type?.name}</span>
            {business?.role?.lastName && <span className="role">{`- ${business.role.lastName}`}</span>}
          </p>
        )}
      </div>
    </div>
  );
};

const SelectBusinessDropdown = ({ short }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const myBusiness = useSelector(state => selectMyBusiness(state));
  const businessId = localStorage.getItem('bId');
  const defaultValue = myBusiness?.find(e => e?.id === +businessId);

  const handleGetBusinessToken = async (id, business) => {
    try {
      const res = await dispatch(getMyBusinessToken(
        {data: { type: 'business', id }}
      ));
      localStorage.setItem('token', res?.data?.accessToken);
      localStorage.setItem('bId', id);
      localStorage.setItem('bgColor', business?.type?.color);
      cbChangeBusiness();
    } catch (error) {
      console.log('error', error);
    }
  };

  const onChangeBusiness = (e) => {
    const { value: business } = e?.target || {};
    handleGetBusinessToken(business?.id, business);
  };

  const onBack = () => {
    navigate(ROUTE_BUSINESS.SELECT_BUSINESS);
  };

  const renderValue = value => <BusinessItem business={value} isInputValue />;
  
  return (
    <Select
      className={classnames(classes.selectBusiness, short && 'short')}
      defaultValue={defaultValue}
      onChange={onChangeBusiness}
      MenuProps={{ className: classes.selectBusinessModal }}
      renderValue={renderValue}
      sx={{
        '& fieldset': {
          borderColor: defaultValue?.type?.color
        }
      }}
    >
      {
        myBusiness?.map(b => (
          <MenuItem className={classes.menuItem} value={b} key={b?.id}>
            <BusinessItem business={b} />
          </MenuItem>
        ))
      }
      <Button className="fb-button-light fb-hover" variant="contained" type="button" size="small" onClick={onBack} fullWidth sx={{ mt: 2 }}>Choose your other business</Button>
    </Select >
  );
};

export default SelectBusinessDropdown;
