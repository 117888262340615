import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { ROUTE_REQUEST } from 'portals/business/routes';
import SideBar from './Sidebar';
import { doLogout } from 'store/actions';
import { matchPath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useStyles from './styled';
import { useTheme } from '@mui/material/styles';

export default function PrivateLayout({ children }) {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isFixedSidebar, setFixedSidebar] = useState(true);
  const classes = useStyles();
  const pathsUnneededLayout = ['/select-business', '/active-user'];
  const fullWidthMain = [
    '/cases',
    '/cases/:id/',
    '/customers',
    '/customers/:id/',
    '/businesses',
    '/businesses/:id/',
    '/requests',
    '/requests/:id/',
    ROUTE_REQUEST.CREATE_REQUEST
  ];

  const handelLogout = () => {
    dispatch(doLogout());
    navigate('/sign-in');
  };

  if (!token) {
    // user is not authenticated
    return <Navigate to="/sign-in" />;
  }

  if (pathsUnneededLayout.includes(location?.pathname)) {
    return (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: '#f0f2f5',
          alignItems: 'center',
          flex: 1,
          height: '100%'
        }}
      >
        <Outlet />
      </Box>
    );
  }

  const isFullWidthMain = !!fullWidthMain.map((path) =>
    matchPath({ path }, location?.pathname)
  );

  return (
    <Box sx={{ display: 'flex' }} className={classes.container}>
      <Box
        className={classes.main}
        component="main"
        sx={{
          flexGrow: 1,
          marginLeft: isFixedSidebar ? '56px' : '240px',
          minHeight: '100vh',
          paddingLeft: isFullWidthMain ? 0 : theme.spacing(2),
          transition: isFixedSidebar
            ? theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
              })
            : theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
              })
        }}
      >
        <Outlet />
      </Box>
      <SideBar setFixedSidebar={setFixedSidebar} onLogout={handelLogout} />
    </Box>
  );
}
