import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PushPinIcon from '@mui/icons-material/PushPin';
import ViewListIcon from '@mui/icons-material/ViewList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dayjs from 'dayjs';

// Manage Customers
// Meta Key
export const DEFAULT_PARAM_METADATA_CUSTOMERS = {
  metaKey: `manageCustomersPinIds,manageCustomersColumnDisplay,manageCustomersSectionDisplay`
};
export const META_PIN_CUSTOMER = 'manageCustomersPinIds';
export const META_SECTION_CUSTOMER = 'manageCustomersSectionDisplay';
export const META_COLUMN_CUSTOMER = 'manageCustomersColumnDisplay';
// Section key
export const MANAGE_CUSTOMERS = 'manageCustomers';
export const NEW_CUSTOMER = 'newCustomer';
export const IMPORT_CUSTOMER = 'importCustomer';
export const PIN_CUSTOMERS = 'pinCustomers';
export const MANAGE_CUSTOMER_SECTIONS_VALUE = [
  NEW_CUSTOMER,
  IMPORT_CUSTOMER,
  PIN_CUSTOMERS,
  MANAGE_CUSTOMERS
];
export const MANAGE_CUSTOMER_SECTIONS = [
  {
    label: 'New Customer',
    value: 'newCustomer',
    order: 1,
    icon: <PlaylistAddIcon />
  },
  {
    label: 'Import Customer',
    value: 'importCustomer',
    order: 2,
    icon: <VisibilityIcon />
  },
  {
    label: 'Pin Customers',
    value: 'pinCustomers',
    order: 3,
    icon: <PushPinIcon />
  },
  {
    label: 'Manage Customers',
    value: 'manageCustomers',
    order: 4,
    icon: <ViewListIcon />
  }
];
// Columns

export const MANAGE_CUSTOMER_COLUMNS = [
  { id: 'pin', label: 'Pin', align: 'center', isDefault: true, order: 1 },
  { id: 'id', label: 'Customer #', isDefault: true, order: 2 },
  {
    id: 'clientName',
    label: 'Client name',
    isDefault: true,
    order: 3
  },
  {
    id: 'dateOfBirth',
    label: 'DoB',
    isDefault: false,
    order: 4
  },
  {
    id: 'gender',
    label: 'Gender',
    isDefault: false,
    order: 5
  },
  {
    id: 'address',
    label: 'Address',
    isDefault: false,
    order: 6
  },
  {
    id: 'created',
    label: 'Created',
    format: (value) => dayjs(value).format('MM/YY'),
    isDefault: false,
    order: 7
  },
  { label: 'Email address', id: 'emailAddress', isDefault: false, order: 8 },
  { label: 'Phone number', id: 'phoneNumber', isDefault: false, order: 9 },
  {
    label: 'City',
    id: 'city',
    isDefault: false,
    order: 10
  },
  {
    label: 'Updated',
    id: 'updated',
    format: (value) => dayjs(value).format('MM/YY'),
    isDefault: false,
    order: 11
  }
];

export const DEFAULT_PARAMS_SEARCH_CUSTOMER = {
  s: '',
  firstName: '',
  lastName: '',
  createdFrom: '',
  createdTo: '',
  updatedFrom: '',
  updatedTo: ''
};
