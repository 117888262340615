import get from 'lodash/get';
export const selectBusinesses = (state) => {
  return get(state, 'businesses.businesses.data', []);
};

export const selectLoadingBusinesses = (state) => {
  return get(state, 'businesses.businesses.isLoading', false);
};

export const selectPinBusinesses = (state) => {
  return get(state, 'businesses.pinBusinesses.data', []);
};

export const selectLoadingPinBusinesses = (state) => {
  return get(state, 'businesses.pinBusinesses.isLoading', false);
};

export const selectParamsSearchBusinesses = (state) => {
  return get(state, 'businesses.paramsSearchBusinesses', {});
};

export const selectBusiness = (state) => {
  return get(state, 'businesses.business.data', {});
};

export const selectLoadingBusiness = (state) => {
  return get(state, 'businesses.business.isLoading', false);
};

export const selectIsSearchAllBusinesses = (state) => {
  return get(state, 'businesses.businesses.isAll', false);
};

export const selectContactMembers = (state) => {
  return get(state, 'businesses.contactMember.data', []);
};

export const selectLoadingContactMembers = (state) => {
  return get(state, 'businesses.contactMember.isLoading', false);
};

export const selectIsSearchAllContactMembers = (state) => {
  return get(state, 'businesses.contactMember.isAll', false);
};

export const selectMembers = (state) => {
  return get(state, 'businesses.members.data', []);
};

export const selectLoadingMembers = (state) => {
  return get(state, 'businesses.members.isLoading', false);
};

export const selectPinMembers = (state) => {
  return get(state, 'businesses.pinMembers.data', []);
};

export const selectLoadingPinMembers = (state) => {
  return get(state, 'businesses.pinMembers.isLoading', false);
};

export const selectParamsSearchMembers = (state) => {
  return get(state, 'businesses.paramsSearchMembers', {});
};

export const selectMember = (state) => {
  return get(state, 'businesses.member.data', {});
};

export const selectLoadingMemberDetail = (state) => {
  return get(state, 'businesses.member.isLoading', false);
};
