import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  selectBusiness: {
    margin: theme.spacing(1),
    marginRight: theme.spacing(2),
    '& .MuiSelect-select': {
      transition: 'all 0.15s ease-in-out'
    },
    '& > .MuiSvgIcon-root': {
      opacity: 1,
      transition: 'opacity 0.15s ease-in-out'
    },
    '&.short': {
      minWidth: '40px',
      minHeight: '40px',
      '& > .MuiSvgIcon-root': {
        opacity: 0
      },
      '& .MuiSelect-select': {
        paddingLeft: '4px',
        paddingRight: '4px'
      },
    },
    '& > div': {
      padding: theme.spacing(0.5, 1),
      paddingRight: theme.spacing(4)
    }
  },
  selectBusinessModal: {
    '& .MuiPaper-root': {
      left: `${theme.spacing(1)} !important`,
      minWidth: '360px !important',
      '& > ul': {
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        '& > li': {
          transition: 'background-color 0.15s ease-in-out',
          borderRadius: theme.spacing(0.75),
          '& + li': {
            marginTop: theme.spacing(0.25)
          }
        }
      }
    }
  },
  menuItem: {
    padding: `${theme.spacing(1)} !important`
  },
  portalBusiness: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '& img': {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: '50%'
    },
    '& .business-info': {
      flex: 1,
      marginLeft: theme.spacing(1),
      '& p': {
        margin: 0,
        '&.name': {
          fontSize: '14px'
        },
        '&.type': {
          fontSize: '12px',
          '& .role': {
            color: 'rgba(0, 0, 0, 0.6)',
            marginLeft: theme.spacing(0.5)
          }
        }
      }
    }
  }
}));

export default useStyles;
