import '../../index.css';

import React, { Suspense } from 'react';
import { persistor, store } from 'portals/personal/configs/store';

import App from './App';
import { AuthProvider } from 'hooks/useAuth';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Loader from 'portals/components/Loader';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from '../business/SnackbarUtils';
import { ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import reportWebVitals from '../../reportWebVitals';
import theme from '../../theme';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<Loader />}>
              <CssBaseline />
              <BrowserRouter>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                >
                  <SnackbarUtilsConfigurator />
                  <App />
                </SnackbarProvider>
              </BrowserRouter>
            </Suspense>
          </PersistGate>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
