import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiPaper-root': {
      // padding: 0,
      // backgroundColor: 'rgb(240, 242, 245)',
    }
  },
  active: {
    backgroundColor: 'rgb(40, 57, 67) !important',
    paddingLeft: '7px !important',
    minWidth: `${theme.spacing(5)} !important`,
    marginLeft: `${theme.spacing(1)} !important`,

    '& .MuiListItemIcon-root': {
      '& .MuiSvgIcon-root': {
        color: 'white !important'
      }
    },
    '& .MuiListItemText-root, & .MuiSvgIcon-root': {
      color: 'white !important'
    }
  },
  popupLogout: {
    '& .MuiPaper-root': {
      minWidth: '268px !important'
    }
  }
}));

export default useStyles;
