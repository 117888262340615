import { ROUTE_HOMEPAGE } from 'portals/business/routes';
import axios from 'axios';
import dayjs from 'dayjs';
import isNaN from 'lodash/isNaN';

export const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
};

export const capFirstLetter = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const formatClientName = (client, hasTitle = true) =>
  [
    (hasTitle ? client?.title : '') || '',
    client?.firstName || client?.lastName
      ? `${client?.firstName || ''} ${client?.lastName || ''}`
      : client?.emailAddress
  ]
    .join(' ')
    ?.trim() ?? '--';

export const getNewCaseTitle = (patient, doi, caseId = '') => {
  const arr = [formatClientName(patient, false)];
  const doi_ = dayjs(doi);
  if (doi_.isValid()) {
    arr.push(`DOI: ${doi_.format('MM/DD/YYYY')}`);
  }
  if (caseId) {
    arr.push(caseId);
  }
  return arr.join(' - ');
};

export const getClientName = (caseDetail) => {
  let clientName = '';
  if (caseDetail?.customer) {
    clientName =
      caseDetail?.customer?.fullName ??
      caseDetail?.customer?.firstName + ' ' + caseDetail?.customer?.lastName;
  } else if (caseDetail?.suggestCustomer) {
    clientName =
      caseDetail?.suggestCustomer?.fullName ??
      caseDetail?.suggestCustomer?.firstName +
        ' ' +
        caseDetail?.suggestCustomer?.lastName;
  }
  return clientName;
};

export const getDoBClient = (caseDetail) => {
  let dateOfBirth = null;
  if (caseDetail?.customer) {
    dateOfBirth = caseDetail?.customer?.birthday;
  } else if (caseDetail?.suggestCustomer) {
    dateOfBirth = caseDetail?.suggestCustomer?.birthday;
  }
  return dateOfBirth;
};

export const sortColumnsByOrder = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};

export const getOptionByValue = (value, options) => {
  return (
    options.find((option) => option.value === value) || { label: '', value: '' }
  );
};

export const cbChangeBusiness = () => {
  window.location.href = ROUTE_HOMEPAGE;
};

export const removeValueFromArray = (array, value, key = null) => {
  const index = array.findIndex(
    (item) => (key && item[key] === value) || item === value
  );
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

export const getBusinessId = (business) =>
  business?.id ? `B${business.id}` : `G${business?.businessGlobalId}`;

export const isImage = (type) =>
  ['image/gif', 'image/jpeg', 'image/png'].includes(type);
export const isPdf = (type) => ['application/pdf'].includes(type);

export const getBlobUrl = (data, filename, type) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob = new Blob([data], { type: 'application/octet-stream' });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    // IE doesn't allow using a blob object directly as link href.
    // Workaround for "HTML7007: One or more blob URLs were
    // revoked by closing the blob for which they were created.
    // These URLs will no longer resolve as the data backing
    // the URL has been freed."
    window.navigator.msSaveBlob(blob, filename);
    return;
  }
  // Other browsers
  // Create a link pointing to the ObjectURL containing the blo
  return window.URL.createObjectURL(blob);
};

export const downloadFile = (data, filename, type) => {
  const d = isImage(type) ? data : getBlobUrl(data, filename, type);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = d;
  tempLink.setAttribute('download', filename);
  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(d);
  }, 100);
};

export const downloadFileBlob = async (fileId) => {
  const portals = isPersonalPortal() ? 'users' : 'business';
  const res = await axios(
    `${process.env.REACT_APP_API_ENDPOINT}/v2/${portals}/files/${fileId}`,
    {
      method: 'GET',
      responseType: 'blob', //Force to receive data in a Blob Format,
      headers: {
        Authorization: `${localStorage.getItem('token')}`
      }
    }
  );
  return res;
};

export const parseFirstNameLastName = (name) => {
  if (typeof name !== 'string') {
    return null;
  }
  const names = name.split(' ');
  return {
    firstName: names[0],
    lastName: names.slice(1).join(' ')
  };
};

export const isPersonalPortal = () =>
  process.env.REACT_APP_PORTAL === 'PERSONAL';

export const formatClientNameDefaultEmail = (client) => {
  const res = formatClientName(client) || client?.emailAddress;
  return res;
};

export const timeSince = (date, now = dayjs(), shortView) => {
  const timeView = (value) => ({
    year: shortView ? `${value}y ago` : (value > 1 ? `${value} years ago` : `${value} year ago`),
    month: shortView ? `${value}m ago` : (value > 1 ? `${value} months ago` : `${value} month ago`),
    day: shortView ? `${value}d ago` : (value > 1 ? `${value} days ago` : `${value} days ago`),
    hour: shortView ? `${value}h ago` : (value > 1 ? `${value} hours ago` : `${value} hour ago`),
    minute: shortView ? `${value}m ago` : (value > 1 ? `${value} minutes ago` : `${value} minute ago`),
    seconds: shortView ? `${value}s ago` : (value > 1 ? `${value} seconds ago` : `${value} seconds ago`),
  });
  const seconds = now.diff(dayjs(date), 'second');

  let interval = seconds / 31536000;

  if (interval > 1) {
    const year = Math.floor(interval);
    return timeView(year).year;
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const month = Math.floor(interval);
    return timeView(month).month;
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const day = Math.floor(interval);
    return timeView(day).day;
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const hour = Math.floor(interval);
    return timeView(hour).hour;
  }
  interval = seconds / 60;
  if (interval > 1) {
    const minute = Math.floor(interval);
    return timeView(minute).minute;
  }
  if (seconds < 10) {
    return 'Just now';
  }
  return Math.floor(seconds) + ' seconds ago';
};

export const getDisplayNameConversation = (conversation) => {
  /* Priority
    1. createdByMember
    2. createdByUser
    3. createdByBusiness
  */
  const displayName =
    formatClientName(conversation?.createdByMember) ||
    formatClientName(conversation?.createdByUser) ||
    formatClientName(conversation?.createdByBusiness);
  return displayName;
};

export const onDownload = async (file) => {
  const res = await downloadFileBlob(file?.id);
  if (res?.status === 200) {
    if (isImage(file?.type)) {
      const blob = new Blob([res?.data], { type: file?.type });
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        downloadFile(reader?.result, file?.filename, file?.type);
      };
    } else {
      downloadFile(res?.data, file?.filename, file?.type);
    }
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const convertOptionItem = (e) => ({ ...e, label: e?.emailAddress });

export const getFullName = (firstName = '', lastName = '') => [firstName, lastName].filter(n => !!n).join(' ');

export const toFixedNumber = (value = 0, dp = 0) => {
  return +parseFloat(value).toFixed(dp);
}

export const numberWithComma = (num = 0, comma = '.') => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, comma);

export const checkNumberTyping = (str = '') => {
  const strValue = str || '';
  const numberValue = +strValue.replaceAll(',', '');
  if (
    isNaN(numberValue)
    || numberValue > 1e10
    || (strValue[strValue.length - 1] === '.' && strValue.indexOf('.') !== strValue.length - 1)
    || !(/^\d+(\.\d+)?$/.test(numberValue))
  ) {
    return false;
  }
  return numberWithComma(numberValue || '', ',') + (strValue.endsWith('.') ? '.' : '');
};
