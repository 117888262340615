import { createTypes } from 'helpers/createTypes';

export const RESET_CASES = 'RESET_CASES';
export const UPDATE_CASE_THUMBNAIL = 'UPDATE_CASE_THUMBNAIL';

export default createTypes(
  'GET_CASES',
  'GET_CASE_DETAIL',
  'GET_PIN_CASES',
  'RESET_PIN_CASES',
  'UPDATE_CASE_DETAIL',
  'UPDATE_PARAMS_SEARCH_CASES',
  'RESET_PARAMS_SEARCH_CASES',
  'UPDATE_PARAMS_SEARCH_CASES_DETAIL',
  'RESET_PARAMS_SEARCH_CASE_DETAIL'
);
