import 'portals/personal/configs/I18n';
import '../../App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { PrivateLayout, PublicLayout } from 'components/Layout';
import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

/* eslint-disable react-hooks/exhaustive-deps */
import { Buffer } from 'buffer';
import { PrivateRoute } from 'components/Routes';
import { pdfjs } from 'react-pdf';

window.Buffer = Buffer;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/**
 * @lazy load import Pages
 */
const Home = lazy(() => import('portals/personal/pages/Home'));
const PageNotFound = lazy(() => import('portals/personal/pages/404'));
const Login = lazy(() => import('portals/personal/pages/Login'));
const SignUp = lazy(() => import('portals/personal/pages/SignUp'));
const TwoFactorVerify = lazy(() =>
  import('portals/personal/pages/TwoFactorVerify')
);
const Profile = lazy(() => import('portals/personal/pages/Profile'));
const ForgotPassword = lazy(() =>
  import('portals/personal/pages/ForgotPassword')
);
const ActiveUser = lazy(() => import('portals/personal/pages/ActiveUser'));
const ModalWrapper = lazy(() => import('components/ModelWrapper'));
const Personal = lazy(() => import('portals/personal/pages/Requests'));
const PersonalDetail = lazy(() => import('portals/personal/pages/RequestDetail'));
const CreateRequest = lazy(() => import('portals/personal/pages/CreateRequest'));

function App() {
  return (
    <div className="App">
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path="/sign-up" exact element={<SignUp />} />
          <Route path="/sign-in" exact element={<Login />} />
          <Route path="/forget-password" exact element={<ForgotPassword />} />
          <Route
            path="/two-factor/:mfaAuth"
            exact
            element={<TwoFactorVerify />}
          />
        </Route>
        <Route element={<PrivateLayout />}>
          <Route path="/" element={<PrivateRoute Component={Home} />} />
          <Route
            exact
            path="/profile"
            element={<PrivateRoute Component={Profile} />}
          />
          <Route
            exact
            path="/active-user"
            element={<PrivateRoute Component={ActiveUser} />}
          />
          <Route
            exact
            path="/dashboard"
            element={<PrivateRoute Component={Home} />}
          />
          <Route path="/requests" exact element={<Personal />} />
          <Route path="/requests/:id/" exact element={<PersonalDetail />} />
          <Route path="/create-request/" exact element={<CreateRequest />} />
        </Route>
        <Route path="*" element={<PageNotFound/>}/>
      </Routes>
      <Suspense>
        <ModalWrapper />
      </Suspense>
    </div>
  );
}

export default App;
