import API from 'api';
import caseTypes, { RESET_CASES, UPDATE_CASE_THUMBNAIL } from '../types/case';

export const getCases = (
  params,
  meta,
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: caseTypes.GET_CASES,
    payload: { isLoadmore },
    asyncCall: async () => {
      const res = await API.getCases(params, meta);
      return res;
    },
    afterSuccessCall
  };
};

export const getPinCases = (params, meta, afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: caseTypes.GET_PIN_CASES,
    payload: {},
    asyncCall: async () => {
      const res = await API.getCases(params, meta);
      return res;
    },
    afterSuccessCall
  };
};

export const getCaseDetail = (
  id,
  meta,
  afterSuccessCall = () => {},
  afterFailureCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: caseTypes.GET_CASE_DETAIL,
    payload: { id },
    asyncCall: async () => {
      const res = await API.getCaseDetail(id, meta);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const resetPinCases = () => {
  return {
    type: caseTypes.RESET_PIN_CASES,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const updateCaseDetail = (pk, payload, afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: caseTypes.UPDATE_CASE_DETAIL,
    payload: {},
    asyncCall: async () => {
      const res = await API.updateCaseDetail(pk, payload);
      return res;
    },
    afterSuccessCall
  };
};

export const updateParamsSearchCases = (payload) => {
  return {
    type: caseTypes.UPDATE_PARAMS_SEARCH_CASES,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const resetParamsSearchCases = () => {
  return {
    type: caseTypes.RESET_PARAMS_SEARCH_CASES,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const updateParamsSearchCaseDetail = (payload) => {
  return {
    type: caseTypes.UPDATE_PARAMS_SEARCH_CASES_DETAIL,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const resetParamsSearchDetail = () => {
  return {
    type: caseTypes.RESET_PARAMS_SEARCH_CASE_DETAIL,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const resetCases = (loading = false) => ({ type: RESET_CASES, loading });

export const updateCaseThumbnail = (thumbnail = '') => ({ type: UPDATE_CASE_THUMBNAIL, data: thumbnail });
