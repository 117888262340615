/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Outlet, useNavigate } from 'react-router-dom';

const PublicLayout = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [isInitialized, setInit] = useState(false);

  useEffect(() => {
    if(!!token) {
      navigate('/');
    } else {
      setInit(true);
    }

  }, []);

  if(!isInitialized) {
    return null;
  }

  return (
    <div style={{ backgroundColor: '#f0f2f5', margin: 0, height: '100%' }}>
      <Outlet />
    </div>
  );
};

PublicLayout.propTypes = {
  Component: PropTypes.any
};

export default PublicLayout;
