import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

export const AvatarWrapper = styled(Avatar)(({ fontSize }) => ({
  color: 'white !important',
}));

export const AvatarCircle = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  border: `1px solid ${theme.palette.grey[300]}`,
  margin: '0 auto',
  width: 'auto',
  height: 'auto'
}));
