import userTypes, { UPDATE_PROVIDER } from '../types/user';

import API from 'api';

export const doLogin = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_LOGIN,
    payload,
    asyncCall: async () => {
      return await API.doLogin(payload);
    },
    afterSuccessCall
  };
};

export const doVerifyLoginOTP = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_VERIFY_LOGIN_OTP,
    payload,
    asyncCall: async () => {
      return await API.doVerifyLoginOTP(payload);
    },
    afterSuccessCall
  };
};

export const doVerifyTwoFactor = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_VERIFY_TWO_FACTOR,
    payload,
    asyncCall: async () => {
      return await API.doVerifyLogin2Factor(payload);
    },
    afterSuccessCall
  };
};

export const sendEmailForgotPassword = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_SEND_FORGOT_PASSWORD,
    payload,
    asyncCall: async () => {
      return await API.sendEmailForgotPassword({
        userType: payload?.userType,
        emailAddress: payload?.emailAddress
      });
    },
    afterSuccessCall
  };
};

export const doVerifyOTPForgotPassword = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_VERIFY_OTP_FORGOT_PASSWORD,
    payload,
    asyncCall: async () => {
      return await API.doVerifyOTPForgotPassword(payload);
    },
    afterSuccessCall
  };
};

export const resetPassword = (payload, afterSuccessCall) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_RESET_PASSWORD,
    payload,
    asyncCall: async () => {
      return await API.resetPassword(payload);
    },
    afterSuccessCall
  };
};

export const updateProfile = (params, afterSuccessCall = () => {}) => {
  const {
    firstName,
    lastName,
    address1,
    address2,
    city,
    stateCode,
    postCode,
    phoneNumber
  } = params;
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.UPDATE_MY_PROFILE,
    payload: {
      firstName,
      lastName,
      address1,
      address2,
      city,
      stateCode,
      postCode,
      phoneNumber
    },
    asyncCall: async () => {
      const res = await API.updateMyProfile(params);
      return res;
    },
    afterSuccessCall
  };
};

export const getMyProfile = () => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.GET_MY_PROFILE,
    payload: {},
    asyncCall: async () => {
      const res = await API.getMyProfile();
      return res;
    }
  };
};

export const updatePassword = (params, afterSuccessCall = () => {}) => {
  const { currentPassword, password } = params;
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.UPDATE_PASSWORD,
    payload: {
      currentPassword,
      password
    },
    asyncCall: async () => {
      const res = await API.updateMyProfile({
        currentPassword,
        newPassword: password
      });
      return res;
    },
    afterSuccessCall
  };
};

export const enableMFA = (params, afterSuccessCall = () => {}) => {
  const { setMfa, mfaSecret, mfaToken } = params;
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.ENABLE_MFA,
    payload: {
      setMfa,
      mfaSecret,
      mfaToken
    },
    asyncCall: async () => {
      const res = await API.updateMyProfile(params);
      return res;
    },
    afterSuccessCall
  };
};

export const resetMyProfile = () => {
  return {
    isAsyncCall: false,
    shouldThrow: true,
    type: userTypes.RESET_ERROR
  };
};

export const resetUser = () => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.RESET_USER,
    asyncCall: async () => {}
  };
};

export const doLogout = () => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.USER_LOGOUT,
    asyncCall: async () => {}
  };
};

export const doResponseBusinessInvitation = (payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.RESPONSE_BUSINESS_INVITATION,
    payload,
    asyncCall: async () => {
      const res = await API.doResponseBusinessInvitation(payload);
      return res;
    }
  };
};

export const getMyBusiness = () => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.GET_MY_BUSINESS,
    payload: {},
    asyncCall: async () => {
      const res = await API.getMyBusiness();
      return res;
    }
  };
};

export const getMyBusinessToken = (payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.GET_MY_BUSINESS_TOKEN,
    payload,
    asyncCall: async () => {
      const res = await API.getMyBusinessToken(payload?.data);
      return res;
    },
    afterSuccessCall: payload.afterSuccessCall,
    afterFailureCall: payload.afterFailureCall
  };
};

export const getProviders = (
  params = {},
  meta = {},
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.GET_PROVIDERS,
    payload: { isLoadmore },
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getProviders(params, meta);
      return res;
    }
  };
};

export const updateProvider = (data) => ({
  type: UPDATE_PROVIDER,
  data
});

export const getCategories = (
  params = {},
  meta = {},
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.GET_CATEGORIES,
    payload: { isLoadmore },
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getCategories(params, meta);
      return res;
    }
  };
};

export const createCategory = (payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.CREATE_CATEGORY,
    payload,
    asyncCall: async () => {
      const res = await API.createCategory(payload);
      return res;
    }
  };
};

export const createCase = (payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.CREATE_CASE,
    payload,
    asyncCall: async () => {
      const res = await API.createCase(payload);
      return res;
    }
  };
};

export const getMyMetadata = (params, afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.GET_MY_METADATA,
    payload: {},
    asyncCall: async () => {
      const res = await API.getMyMetadata(params);
      return res;
    },
    afterSuccessCall
  };
};

export const updateMyMetadata = (payload, afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.UPDATE_MY_METADATA,
    payload: {},
    asyncCall: async () => {
      const res = await API.updateMyMetadata(payload);
      return res;
    },
    afterSuccessCall
  };
};

export const updateAvatar = (formData, afterSuccessCall = () => {}) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: userTypes.UPDATE_AVATAR,
    payload: {},
    asyncCall: async () => {
      const res = await API.updateMyProfile(formData);
      return res;
    },
    afterSuccessCall
  };
};
