/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { getMyProfile } from 'store/actions';
import { selectMyProfile } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

const PrivateRoute = ({ Component }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => selectMyProfile(state));

  useEffect(() => {
    dispatch(getMyProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile?.isVerified === false) {
      navigate('/active-user');
    }
  }, [profile]);

  return (
      <Component />
  );
};

PrivateRoute.propTypes = {
  Component: PropTypes.any
};

export default PrivateRoute;
