import { createTypes } from 'helpers/createTypes';

export default createTypes(
  'GET_CUSTOMERS',
  'CREATE_CUSTOMER',
  'GET_PIN_CUSTOMERS',
  'UPDATE_PARAMS_SEARCH_CUSTOMERS',
  'RESET_PARAMS_SEARCH_CUSTOMERS',
  'GET_CUSTOMER_DETAIL',
  'UPDATE_CUSTOMER_DETAIL'
);
