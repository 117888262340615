import API from 'api';
import isEmpty from 'lodash/isEmpty';
import customerTypes from '../types/customer';

export const getCustomers = (
  params = {},
  meta = {},
  isLoadmore = false,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: customerTypes.GET_CUSTOMERS,
    payload: { isLoadmore, isAll: isEmpty(params) },
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getCustomers(params, meta);
      return res;
    }
  };
};

export const getPinCustomers = (
  params = {},
  meta = {},
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: customerTypes.GET_PIN_CUSTOMERS,
    payload: {},
    afterSuccessCall,
    asyncCall: async () => {
      const res = await API.getCustomers(params, meta);
      return res;
    }
  };
};

export const createCustomer = (payload) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: customerTypes.CREATE_CUSTOMER,
    payload,
    asyncCall: async () => {
      const res = await API.createCustomer(payload);
      return res;
    }
  };
};

export const updateParamsSearchCustomers = (payload) => {
  return {
    type: customerTypes.UPDATE_PARAMS_SEARCH_CUSTOMERS,
    shouldThrow: true,
    payload,
    isAsyncCall: false
  };
};

export const resetParamsSearchCustomers = () => {
  return {
    type: customerTypes.RESET_PARAMS_SEARCH_CUSTOMERS,
    shouldThrow: true,
    isAsyncCall: false
  };
};

export const getCustomerDetail = (
  id,
  meta,
  afterSuccessCall = () => {},
  afterFailureCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: customerTypes.GET_CUSTOMER_DETAIL,
    payload: { id },
    asyncCall: async () => {
      const res = await API.getCustomerDetail(id, meta);
      return res;
    },
    afterSuccessCall,
    afterFailureCall
  };
};

export const updateCustomerDetail = (
  pk,
  payload,
  afterSuccessCall = () => {}
) => {
  return {
    isAsyncCall: true,
    shouldThrow: true,
    baseType: customerTypes.UPDATE_CUSTOMER_DETAIL,
    payload: {},
    asyncCall: async () => {
      const res = await API.updateCustomerDetail(pk, payload);
      return res;
    },
    afterSuccessCall
  };
};
