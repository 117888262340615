import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiPaper-root': {
      // padding: 0,
      // backgroundImage: 'linear-gradient(to right, red , yellow)',
    }
  },
  main: {
    backgroundColor: '#F2F3F5',
    // backgroundImage: 'linear-gradient(to right, #f2f8fc, #ebf4fd , #eee2f7)',
    height: '100%',
    '& .main-content': {
      height: '100%',
      minWidth: '1100px',
      maxWidth: '1262px',
      margin: 'auto',
      boxShadow: 'none',
      backgroundColor: 'transparent'
    },
    '& .main-width-content': {
      minWidth: '1100px',
      maxWidth: '1262px',
      margin: 'auto'
    },
    '& .no-padding-top': {
      paddingTop: 0
    }
  }
}));

export default useStyles;
