import get from 'lodash/get';
export const selectCases = (state) => {
  return get(state, 'cases.cases.data', []);
};

export const selectLoadingCases = (state) => {
  return get(state, 'cases.cases.isLoading', false);
};

export const selectCase = (state) => {
  return get(state, 'cases.case.data', {});
};

export const selectLoadingCase = (state) => {
  return get(state, 'cases.case.isLoading', false);
};

export const selectErrorCase = (state) => {
  return get(state, 'cases.error.case', null);
};

export const selectPinCases = (state) => {
  return get(state, 'cases.pinCases.data', []);
};

export const selectLoadingPinCases = (state) => {
  return get(state, 'cases.pinCases.isLoading', false);
};

export const selectCategories = (state) => {
  return get(state, 'user.category.list', []);
};

export const selectUpdateCaseError = (state) => {
  return get(state, 'cases.error.updateCase', null);
};

export const selectParamsSearchCases = (state) => {
  return get(state, 'cases.paramsSearchCases', {});
};

export const selectParamsSearchCaseDetail = (state) => {
  return get(state, 'cases.paramsSearchCaseDetail', {});
};
