import clsx from 'classnames';

// import defaultBusiness from 'assets/imgs/business-default.jpg';

import { getUpperLetters, stringToColor } from './helpers';
import { SIZE, FONT_SIZE, WIDTH } from './consts';

import { AvatarWrapper, AvatarCircle } from './styled';

export { SIZE, FONT_SIZE, WIDTH };

const Avatar = ({
  circle = false,
  circlePadding = 8, // px
  ...props
}) => {
  if (circle) {
    const circleSize = props?.sx?.width + circlePadding;
    return (
      <AvatarCircle sx={{ width: circleSize, height: circleSize, padding: `${circlePadding}px` }}>
        <AvatarWrapper {...props} />
      </AvatarCircle>
    );
  }
  return (
    <AvatarWrapper {...props} />
  );
};

export default ({ business, src, size = SIZE.S, ...props }) => {
  const bProps = {
    ...props,
    className: clsx('avatar-business', props.className),
    fontSize: FONT_SIZE[size],
    sx: {
      ...(props?.sx || {}),
      width: WIDTH?.[size],
      height: WIDTH?.[size],
    }
  };
  if (business?.logo) {
    return <Avatar {...bProps} src={business.logo} />;
  }
  const str = business?.name;
  if (str) {
    return (
      <Avatar {...bProps} sx={{ backgroundColor: stringToColor(str), ...bProps?.sx }}>
        {getUpperLetters(str)}
      </Avatar>
    );
  }
  return <Avatar {...bProps} src="" />;
};
