import { createTypes } from 'helpers/createTypes';

export const APPLY_ALL__CREATE_REQUEST = 'APPLY_ALL__CREATE_REQUEST';
export const ADD_EMPTY__CREATE_REQUEST = 'ADD_EMPTY__CREATE_REQUEST';
export const CLEAR__CREATE_REQUEST = 'CLEAR__CREATE_REQUEST';
export const DELETE__CREATE_REQUEST = 'DELETE__CREATE_REQUEST';
export const SET_TOTAL_STEP__CREATE_REQUEST = 'SET_TOTAL_STEP__CREATE_REQUEST';
export const SET_COMPLETED_DATA__CREATE_REQUEST =
  'SET_COMPLETED_DATA__CREATE_REQUEST';
export const UPDATE_PREVIEW_LETTER__CREATE_REQUEST =
  'UPDATE_PREVIEW_LETTER__CREATE_REQUEST';
export const UPDATE_REQUEST__REQUEST_DETAIL = 'UPDATE_REQUEST__REQUEST_DETAIL';

export default createTypes(
  'GET_REQUESTS',
  'GET_PIN_REQUESTS',
  'UPDATE_PARAMS_SEARCH_REQUESTS',
  'RESET_PARAMS_SEARCH_REQUESTS',
  'GET_REQUEST_STATUSES',
  'GET_REQUEST_TYPES',
  'GET_RESOLUTIONS',
  'CREATE_REQUEST',
  'GET_LETTER_CONTENT',
  'GET_REQUEST_TEMPLATES',
  'GET_PREVIEW_LETTER',
  'REPLY_REQUEST',
  'GET_REQUEST_HASHTAGS',
  'UPDATE_REQUEST_HASHTAGS',
  'CREATE_REQUEST_HASHTAG',
  'UPDATE_REQUEST_DETAIL',
  'CREATE_CONVERSATION',
  'UPDATE_CONVERSATION',
  'SEND_MESSAGE_CONVERSATION',
  'UPDATE_SHARED',
  'RESET_SHARED',
  'RESPONSE_REQUEST_DEAL',
  'GET_BUSINESS_REGISTERED',
  'REQUEST_CREATE_BUSINESS',
  'GET_REQUEST_CONVERSATIONS',
);
