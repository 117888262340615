
export const SIZE = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
  '2XL': '2xl',
  '3xl': '3xl',
};

export const WIDTH = {
  [SIZE.XS]: 24,
  [SIZE.S]: 32,
  [SIZE.M]: 40,
  [SIZE.L]: 52,
  [SIZE.XL]: 92,
  [SIZE['2XL']]: 140,
  [SIZE['3xl']]: 180,
};

export const FONT_SIZE = {
  [SIZE.XS]: 10,
  [SIZE.S]: 14,
  [SIZE.M]: 16,
  [SIZE.L]: 16,
  [SIZE.XL]: 24,
  [SIZE['2XL']]: 40,
  [SIZE['3XL']]: 52,
};
